import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from 'views/Company/components/AdvisorsSection/AdvisorsSection';
import AryzeCoin from 'images/Support/ARYZE-eEUR-Stablecoin.svg';
import MamaLogo from 'images/Support/mama-logo.svg';
import PenningLogo from 'images/Support/penning-logo.svg';
import SwitchereLogo from 'images/Support/switchere-logo.svg';
import ScrollShadow from 'views/Properties/components/Table/modules/ScrollShadow';
import Link from 'layouts/Main/components/Footer/FooterLink';
import * as Styles from './styles';

const content = {
  whereToGet: [
    {
      id: 1,
      row1: '',
      row2: SwitchereLogo,
      row3: PenningLogo,
      row4: MamaLogo,
    },
    {
      id: 2,
      row1: 'Purchasing Fee*',
      row2: 'From 3%',
      row3: 'From 0,5%',
      row4: 'From 0,3%',
    },
    {
      id: 3,
      row1: 'Minimum amount',
      row2: 'No minimum',
      row3: '€ 1000, -',
      row4: 'No minimum',
    },
    {
      id: 4,
      row1: 'KYC required',
      row2: '>150 $',
      row3: 'Yes',
      row4: 'No',
    },
    {
      id: 5,
      row1: 'Accepts digital assets',
      row2: 'Yes',
      row3: 'Yes',
      row4: 'Yes',
    },
    {
      id: 6,
      row1: 'Payments Methods',
      row2: 'Bank transfers, Credit card, Swap',
      row3: 'Bank transfer, Swap',
      row4: 'Swap',
    },
    {
      id: 7,
      row1: 'Accepts MetaMask wallets',
      row2: 'Yes',
      row3: 'Yes',
      row4: 'Yes',
    },
    {
      id: 8,
      row1: 'Link',
      row2: 'https://switchere.com/',
      row3: 'https://www.penning.dk/',
      row4: 'https://defi.mama.io',
    },
  ],
};

function formatContent(input: string) {
  const isLink = (value: string) => value.includes('http');
  const isSVG = (value: string) => value.includes('svg');
  if (isSVG(input)) return <Styles.RowLogo src={input} />;
  if (isLink(input))
    return (
      <Link
        href={input}
        target="_blank"
        style={{ fontWeight: 800, color: '#1C5695' }}
      >
        {input}
      </Link>
    );
  return input;
}

const WhereToGetEEurSection = (): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.WhereToGetEEurSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <Styles.ContentBox className="whereToGetEEur-contentBox">
            <Anchor id="where-to-get-eeu" />
            <Styles.SubTitle
              component="h3"
              className="whereToGetEEur-subTitle subtitle-flex"
            >
              Where to get <Styles.AryzeCoinSVG src={AryzeCoin} /> Aryze eEur
            </Styles.SubTitle>
            <ScrollShadow>
              <Styles.Table>
                <colgroup>
                  <col style={{ width: isXs ? '60%' : '40%' }} />
                  <col style={{ width: isXs ? '40%' : '40%' }} />
                  <col style={{ width: isXs ? '60%' : '40%' }} />
                  <col style={{ width: isXs ? '40%' : '40%' }} />
                </colgroup>
                <tbody className="reverseAlternateRows stickyFirstColumn">
                  {content.whereToGet.map((line) => (
                    <tr
                      key={line.id}
                      className="noWrapFirstColumn allBlueButFirst"
                    >
                      <td>{formatContent(line.row1)}</td>
                      {/* <td>{formatContent(line.row2)}</td> */}
                      <td>{formatContent(line.row3)}</td>
                      <td>{formatContent(line.row4)}</td>
                    </tr>
                  ))}
                </tbody>
              </Styles.Table>
            </ScrollShadow>

            <Divider height="1.3em" />

            <Styles.Text component="p" className="whereToGetEEur-text">
              * Service providers charge fees, which may vary based on their fee
              structure. Coreestate does not facilitate the sale, purchase, or
              exchange of eEUR. To obtain eEUR, investors must directly engage
              with eEUR distributors.
            </Styles.Text>
          </Styles.ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </Styles.WhereToGetEEurSectionStyles>
  );
};

export default WhereToGetEEurSection;
