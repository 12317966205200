import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

export default function ScrollShadow(props: { children: React.ReactNode }) {
  const TableShadowStyles = styled(Box)<{ index?: number }>`
    position: relative;

    .container-wrapper {
      position: relative;
      ${({ theme }) => theme.breakpoints.down('md')} {
        overflow-x: auto;
      }
    }

    .scroll-shadow {
      position: absolute;
      height: 100%;
      width: 11px;
      right: 0;
      z-index: 1;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      ${({ theme }) => theme.breakpoints.up('md')} {
        background: unset;
      }
    }
  `;

  return (
    <TableShadowStyles>
      <div className="scroll-shadow" />
      <div className="container-wrapper">{props.children}</div>
    </TableShadowStyles>
  );
}
