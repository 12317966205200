import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { StyledPropsFix } from 'types';
import { Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

export const CheckIcon = styled(DoneIcon)<{ iconcolor?: string }>`
  /* width: 3em; */
  margin-right: 1em;
  color: ${({ theme, iconcolor }) =>
    iconcolor ? iconcolor : theme.palette.text.secondary};
  font-size: 1.5em;
`;

export const ActionButton = styled(Button)`
  ${({ theme }) => `
  text-transform: none;
  font-family: Roboto Regular;
  color: ${theme.palette.text.secondary};

  white-space: nowrap;
  &:hover {
    background-color: #58A88F;
  }

  ${theme.breakpoints.down('md')} {
    font-size: 1.125em;
    width: min-content;
    align-self: center;
    margin: 0;
    transform: scale(0.7);
  }
  `}
` as StyledPropsFix;

export const Title = styled(Typography)`
  ${({ theme }) => `
  font-family: Poppins SemiBold;
  color: ${theme.palette.text.secondary};

  ${theme.breakpoints.up('md')} {
   font-size: 2.1em;
   margin: 1em 0;
   line-height: 50px;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   text-align: center;
  }
  ${theme.breakpoints.only('sm')} {
   font-size: 1.6em;
   margin-bottom: 1.0em;
   line-height: 40px;
  }
  ${theme.breakpoints.only('xs')} {
   font-size: 1.2em;
   margin: 0 0 1.0em 0;
   line-height: inherit;
  }
  `}
` as StyledPropsFix;

export const InfoText = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-bottom: 1.6em;
  color: ${theme.palette.text.secondary};

  ${theme.breakpoints.up('md')} {
    font-size: 1.1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.9em;
    text-align: center;
  }
  ${theme.breakpoints.only('xs')} {
  text-align: left;
  }
`}
` as StyledPropsFix;

export const InfoBox = styled(Box)`
  ${({ theme }) => `
  text-align: left;
  align-self: start;

  ${theme.breakpoints.up('md')} {
   flex-direction: initial;
   display: inline-block;
   align-self: auto;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   flex-direction: column;
   display: flex;
   align-self: auto;
   order: 4;
   margin: 2em 4em;
  }
  ${theme.breakpoints.only('xs')} {
   margin: 4em 0em;
  }
`}
`;

export const BenefitsText = styled(Typography)`
  ${({ theme }) => `
   font-family: Roboto Regular;
   color: ${theme.palette.text.secondary};
   display: flex;
   align-items: center;
   padding: 0 1em 1.8em;

  ${theme.breakpoints.up('md')} {
   font-size: 1.2em;
   padding-left: 0;
  }
  ${theme.breakpoints.down('md')} {
   font-size: 1.1em;
  }
`}
` as StyledPropsFix;

export const BenefitsBox = styled(Box)`
  ${({ theme }) => `
   font-family: Roboto Bold;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

  ${theme.breakpoints.up('md')} {
    font-size: 0.8em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.6em;
  }
  ${theme.breakpoints.only('xs')} {
   flex-direction: column;
}
`}
` as StyledPropsFix;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  ${({ theme, sizeofgrid }) => `
  height: 100;
  position: relative;
  margin: 4em auto;

  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: unset;
    align-items: initial;
    ${sizeofgrid && `max-width: ${sizeofgrid}px;`}
  }
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`}
`;

export const ContentBox = styled(Box)`
  ${() => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(89.91deg, #3F2A62 4.16%, rgba(63, 42, 98, 0.76) 96.52%);
  `}
`;

export const PictureBox = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.up('md')} {
   display: inline-block;
   position: relative;
   width: 58em;
   order: initial;
  }

  ${theme.breakpoints.only('sm')} {
   display: flex;
   position: relative;
   width: 28rem;
   order: 1;
  }
  ${theme.breakpoints.only('xs')} {
   display: flex;
   position: relative;
   width: 20rem;
   order: 1;
  }
`}
`;

export const Picture = styled(Box)`
  object-fit: contain;
` as StyledPropsFix;
