import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { CheckIcon } from 'views/IndexView/components/TradeTokens/styles';
import { useTheme } from '@mui/material/styles';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from 'views/Company/components/AdvisorsSection/AdvisorsSection';

const ContentBox = styled(Container)``;
const RequirementText = styled(Typography)`` as StyledPropsFix;
const RequirementListBox = styled(Box)`` as StyledPropsFix;
const Title = styled(Typography)`` as StyledPropsFix;
const SubTitle = styled(Typography)`` as StyledPropsFix;

const content = [
  {
    id: 1,
    // eslint-disable-next-line quotes
    text: "Be at least 18 years old (we'll ask for proof)",
  },
  {
    id: 2,
    // eslint-disable-next-line quotes
    text: "A government-issued photo ID (we don't accept passport cards)",
  },
  {
    id: 3,
    text: 'A computer or smartphone connected to the internet',
  },
  {
    id: 4,
    text: 'Email address to receive an activation link.',
  },
  {
    id: 5,
    text: 'The latest version of your browser (we recommend Chrome).',
  },
  {
    id: 6,
    text: 'Digital currency Wallet (MetaMask, Trust, Coinbase, etc...).',
  },
  {
    id: 7,
    text: 'Ethereum to cover the gas fees if you purchase with EUROC or eEUR.',
  },
  {
    id: 8,
    // eslint-disable-next-line quotes
    text: "Coreestate doesn't charge a fee to create or maintain your Coreestate account.",
  },
];

const GettingStartedSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .gettingStarted-contentBox {
    display: flex;
    flex-direction: column;
    padding: 0 1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
    }
  }
  .gettingStarted-Title {
    font-family: Poppins SemiBold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 2.1em;
      line-height: 50px;
    }
  }
  .gettingStarted-SubTitle {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1.5em;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 1.1em;
    }
  }
  .gettingStarted-requirementListBox {
    font-family: Roboto Bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.only('xs')} {
      flex-direction: column;
    }
  }
  .gettingStarted-requirementListText {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    align-items: center;
    padding: 0 0 1.8em;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 1.1em;
      padding-left: 0;
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
    }
  }
`;

const GettingStartedSection = (): JSX.Element => {
  const theme = useTheme();
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <GettingStartedSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <ContentBox className="gettingStarted-contentBox">
            <Title className="gettingStarted-Title">Getting started</Title>
            <SubTitle className="gettingStarted-SubTitle">
              What you&apos;ll need to get started?
            </SubTitle>
            <Anchor id="getting-started" />

            <RequirementListBox className="gettingStarted-requirementListBox">
              {content.map((line) => (
                <RequirementText
                  component="p"
                  key={line.id}
                  className="gettingStarted-requirementListText"
                >
                  <CheckIcon iconcolor={theme.palette.background.viridian} />
                  {line.text}
                </RequirementText>
              ))}
            </RequirementListBox>
          </ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </GettingStartedSectionStyles>
  );
};

export default GettingStartedSection;
