import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from 'views/Company/components/AdvisorsSection/AdvisorsSection';

const ContentBox = styled(Container)``;
const Title = styled(Typography)`` as StyledPropsFix;
const SubTitle = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;

const SecuritySectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .security-contentBox {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-right: 18em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
  }
  .security-Title {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 0.5em;
    font-size: 2.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
    }
  }
  .security-subTitle {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;
    line-height: 34px;
    font-size: 1.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1em;
    }
  }
  .security-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: left;
    font-size: 1.1em;
    line-height: 34px;
    padding-bottom: 1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }
  }
`;

const SecuritySection = (): JSX.Element => {
  const theme = useTheme();
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <SecuritySectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <ContentBox className="security-contentBox">
            <Title className="security-Title">Security</Title>

            <SubTitle component="h4" className="security-subTitle">
              A leading real estate tokenization network is protecting your
              investments.
            </SubTitle>
            <Text component="p" className="security-text">
              Coreestate takes a comprehensive approach to protect your
              investments. Our network of digital experts have built-in a number
              of sophisticated measures to prevent the theft of money, data or
              other information.
            </Text>
            <Text component="p" className="security-text">
              For instance, Coreestate supports the safety of digital assets
              with secure third-party custodians. To cater to non-digital-asset
              users, we support non-tokenized share classes and on- and
              off-ramping of fiat payments (USD and EUR) which is automatically
              converted into the corresponding stablecoins in order to ensure a
              high degree of internal automation and efficiency on the platform.
            </Text>
            <Text component="p" className="security-text">
              To purchase non-tokenized share classes, please contact support.
            </Text>
          </ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </SecuritySectionStyles>
  );
};

export default SecuritySection;
