import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from 'views/Company/components/AdvisorsSection/AdvisorsSection';

const ContentBox = styled(Container)``;
const Title = styled(Typography)`` as StyledPropsFix;
const SubTitle = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;

const InvestingRisksSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .investingRisks-contentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-right: 18em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
  }
  .investingRisks-Title {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 0.5em;
    font-size: 2.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
    }
  }
  .investingRisks-subTitle {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;
    line-height: 34px;
    font-size: 1.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1em;
    }
  }
  .investingRisks-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: left;
    font-size: 1.1em;
    line-height: 34px;
    padding-bottom: 1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }
  }
`;

const InvestingRisksSection = (): JSX.Element => {
  const theme = useTheme();
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <InvestingRisksSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <ContentBox className="investingRisks-contentBox">
            <Title className="investingRisks-Title">Investing and risks</Title>
            <Anchor id="investing-and-risks" />
            <Text component="p" className="investingRisks-text">
              No investment guarantees safe returns. They all involve risk.
              However, Coreestate has taken extensive measures to create an
              investment that is as safe as possible.
            </Text>
            <Text component="p" className="investingRisks-text">
              We have assessed the potential risks and taken steps to mitigate
              as many risks as possible. However, there are circumstances we
              can&apos;t control and would like you to consider:
            </Text>
            <Text component="p" className="investingRisks-text">
              Investors should refer to the offering documentation of the
              projects for detailed information on risk factors.
            </Text>

            <Divider height="1.3em" />

            <SubTitle component="h4" className="investingRisks-subTitle">
              Real estate market crash.
            </SubTitle>
            <Text component="p" className="investingRisks-text">
              No one can protect us against another 2008 real estate market
              crash. In such a scenario your tokens might temporarily lose value
              since they are correlated to real estate. Historically, real
              estate has been one of the highest returning asset classes with
              long term potential.
            </Text>

            <Divider height="1.3em" />

            <SubTitle component="h4" className="investingRisks-subTitle">
              Tokens repurchased in the aftermarket or exchanges.
            </SubTitle>
            <Text component="p" className="investingRisks-text">
              We raise funds to develop our properties by selling tokens on the
              Coreestate platform at a fixed price. However, when token owners
              decide to sell their tokens in aftermarket or other exchange
              platforms, the free market rules occur.
            </Text>
            <Text component="p" className="investingRisks-text">
              The token price is not regulated by any governmental authority,
              but rather by the community and is based on the supply and demand
              principles.
            </Text>
          </ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </InvestingRisksSectionStyles>
  );
};

export default InvestingRisksSection;
