import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';
import { Link } from '@mui/material';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from 'views/Company/components/AdvisorsSection/AdvisorsSection';

const ContentBox = styled(Container)``;
const Title = styled(Typography)`` as StyledPropsFix;
const SubTitle = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;
const ProjectLink = styled(Link)``;

const ContactUsSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 34px !important;

  .contactUs-contentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-right: 18em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
  }
  .contactUs-Title {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 0.5em;
    font-size: 2.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
    }
  }
  .contactUs-subTitle {
    font-family: Roboto Bold;
    text-align: left;
    line-height: 34px;
    color: ${({ theme }) => theme.palette.text.primary};

    &:nth-of-type(odd) {
      padding-bottom: 1em;
    }

    font-size: 1.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1em;
    }
  }
  .contactUs-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: left;
    font-size: 1.1em;
    padding-bottom: 1em;
    line-height: 34px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }
  }
  .contactUs-link {
    font-family: Roboto Regular;
    white-space: nowrap;
    display: inline-block;
    font-size: 1em;
  }
`;

const ContactUsSection = (): JSX.Element => {
  const theme = useTheme();
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <ContactUsSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <ContentBox className="contactUs-contentBox">
            <Title className="contactUs-Title">Contact us</Title>
            <Anchor id="contact-us" />
            <SubTitle component="h4" className="contactUs-subTitle">
              Need support? Have a question? Running into a problem?
            </SubTitle>
            <SubTitle component="h4" className="contactUs-subTitle">
              We&apos;re here to help. 24/7 customer support service.
            </SubTitle>

            <Text component="p" className="contactUs-text">
              If you have questions before, during, or after your investment,
              you can always contact us by phone or email and we will answer as
              soon as possible.
            </Text>
            <Text component="p" className="contactUs-text">
              E-mail us at{' '}
              <ProjectLink
                href="mailto:hello@coreestate.io"
                className="contactUs-link"
              >
                hello@coreestate.io
              </ProjectLink>
            </Text>
          </ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </ContactUsSectionStyles>
  );
};

export default ContactUsSection;
