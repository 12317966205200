import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Anchor, Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import { GridSizedContainer } from 'views/Company/components/AdvisorsSection/AdvisorsSection';
import { formatText } from 'views/Learn/components/AboutToken/AboutToken';
import * as Styles from './styles';

const content = {
  pricing: [
    {
      id: 1,
      row1: 'Creating account',
      row2: 'FREE',
    },
    {
      id: 2,
      row1: 'Token purchasing',
      row2: 'FREE',
    },
    {
      id: 3,
      row1: 'Asset Under Management',
      row2: 'Self Custody',
    },
    {
      id: 4,
      row1: 'Payment Provider',
      row2: '0-4% - depends on payment method',
    },
    {
      id: 5,
      row1: 'Blockchain transaction fees',
      // eslint-disable-next-line quotes
      row2: "Determined by Ethereum network. Check ETH gas fees <a href='https://etherscan.io/gastracker' target='_blank' rel='noopener'>here</a>",
    },
    {
      id: 6,
      row1: 'One-time crypto wallet whitelisting costs',
      row2: '€ 10,-',
    },
    {
      id: 7,
      row1: 'Business account verification',
      row2: '€ 50,-',
    },
  ],
};

const PricingAndFeesSection = (): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.PricingAndFeesSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <GridSizedContainer sizeofgrid={sizeOfGrid?.toString()}>
          <Styles.ContentBox className="pricingAndFees-contentBox">
            <Styles.Title component="h2" className="pricingAndFees-Title">
              Pricing and fees
            </Styles.Title>
            <Anchor id="pricing-and-fees" />
            <Styles.PricingTable>
              <colgroup>
                <col style={{ width: isXs ? '60%' : '40%' }} />
                <col style={{ width: isXs ? '40%' : '40%' }} />
              </colgroup>
              <tbody>
                {content.pricing.map((line) => (
                  <tr key={line.id}>
                    <td>{line.row1}</td>
                    <td>{formatText(line.row2)}</td>
                  </tr>
                ))}
              </tbody>
            </Styles.PricingTable>

            <Divider height="4.3em" />

            <Styles.SubTitle component="h3" className="pricingAndFees-subTitle">
              Transaction fees
            </Styles.SubTitle>
            <Styles.Text component="p" className="pricingAndFees-text">
              Coreestate may charge fees when we buy, sell, or convert digital
              euros. Fees are calculated at the time order is placed and will be
              determined by a combination of factors including the selected
              payment method, the size of the order, and market conditions such
              as volatility and liquidity.
            </Styles.Text>
          </Styles.ContentBox>
          <Divider height="4.3em" />
        </GridSizedContainer>
      </Box>
    </Styles.PricingAndFeesSectionStyles>
  );
};

export default PricingAndFeesSection;
