import React from 'react';
import Support from 'views/Support';
import { SEO as Head } from 'components/seo';

const SupportPage = (): JSX.Element => {
  const seo = {
    title: 'Coreestate Support When You Need It',
    description:
      'Coreestate Support - Get started with ease and ask customer support if you encouner difficulties. From account creation to investment guidance, our team is here to assist you every step of the way. Contact us at hello@coreestate.io for prompt responses to all your inquiries.',
    pathname: '/support',
  };

  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <Support />
    </>
  );
};

export default SupportPage;
