import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';

export const ContentBox = styled(Container)``;
export const PricingTable = styled('table')`` as StyledPropsFix;
export const Title = styled(Typography)`` as StyledPropsFix;
export const SubTitle = styled(Typography)`` as StyledPropsFix;
export const Text = styled(Typography)`` as StyledPropsFix;
export const PricingAndFeesSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .pricingAndFees-contentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-right: 18em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
  }
  .pricingAndFees-Title {
    font-family: Poppins SemiBold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;
    font-size: 2.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
    }
  }
  .pricingAndFees-subTitle {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;
    font-size: 1.5em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1.1em;
    }
  }
  .pricingAndFees-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: left;
    line-height: 34px;
    font-size: 1.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    max-width: 45em;
    ${({ theme }) => theme.breakpoints.only('xs')} {
      font-size: 0.8em;
      max-width: unset;
      width: 100%;
    }
  }

  td,
  th {
    text-align: left;
    padding: 1.5em;
    font-family: Roboto Bold;
    font-size: 1em;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.background.level1};
  }
  td:nth-of-type(even) {
    color: ${({ theme }) => theme.palette.text.blue};
  }
`;
