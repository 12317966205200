import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';

export const ContentBox = styled(Container)``;
export const Table = styled('table')`` as StyledPropsFix;
export const SubTitle = styled(Typography)`` as StyledPropsFix;
export const Text = styled(Typography)`` as StyledPropsFix;
export const RowLogo = styled('img')``;
export const AryzeCoinSVG = styled('img')`
  padding: 0 0.5rem;
`;

export const WhereToGetEEurSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .whereToGetEEur-contentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-right: 18em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
  }
  .whereToGetEEur-Title {
    font-family: Poppins SemiBold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;
    font-size: 2.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.6em;
    }
  }
  .whereToGetEEur-subTitle {
    font-family: Roboto Bold;
    text-align: left;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1em;
    font-size: 1.5em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1em;
    }
  }

  .subtitle-flex {
    display: inline-flex;
    align-items: center;
  }

  .whereToGetEEur-text {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: left;
    line-height: 34px;
    font-size: 1.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1em;
      padding-top: 1em;
    }
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    max-width: 45em;
    ${({ theme }) => theme.breakpoints.only('xs')} {
      font-size: 0.8em;
      max-width: unset;
      width: 100%;
    }
    border-top: 0.5px solid #b9b9b9;
  }

  td,
  th {
    text-align: left;
    padding: 1.5em;
    font-family: Roboto Bold;
    font-size: 1em;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.background.level1};
  }
  td:nth-of-type(even) {
    color: ${({ theme }) => theme.palette.text.blue};
  }

  .reverseAlternateRows {
    tr:nth-of-type(odd) {
      background-color: unset;
    }
    tr:nth-of-type(even) {
      background-color: ${({ theme }) => theme.palette.background.level1};
    }
  }

  .noWrapFirstColumn {
    td:first-of-type {
      white-space: nowrap;
      ${({ theme }) => theme.breakpoints.down('sm')} {
        white-space: normal;
      }
    }
  }

  .allBlueButFirst {
    td:not(:first-of-type) {
      color: ${({ theme }) => theme.palette.text.blue};
      max-width: 12rem;
    }
  }
  .stickyFirstColumn {
    td:first-of-type {
      position: sticky;
      left: 0;
    }
    tr:nth-of-type(even) {
      td:first-of-type {
        background-color: ${({ theme }) => theme.palette.background.level1};
      }
    }

    tr:nth-of-type(odd) {
      td:first-of-type {
        background-color: white;
      }
    }
  }
`;
